import i18n from '@/lang'
import {paymentSetting} from '@/constants/payment/depositSetting'

export default {
  data() {
    return {
      cryptoPayment: [
        'T00400', // Eth
      ],
      bankTransferTemplate: [
        'T00600_055', // India Bank Transfer
        'T00600_056', // Dollarsmart-FPS-KRW
        'T00600_054', // Internet Banking (Brazil, Chile, Colombia, Mexico, Peru)
        'T00600_076', // PaymentAsia IDR / PHP
        'T00312_033', // PIX
      ],
      qrPaymentTemplate: [
        'T00312_026', // India UPI
      ],
      qrPaymentTemplate2: [
        'T00312_023', // Dollarsmart-FPSQR-HKD
      ],
      fasapayTemplate: [
        'T00100_051', // Fasapay
      ],
      alipayTemplate: [
        'T00312' // Alipay
      ],
      thailandLBTTemplate: [
        'T00600' // Thailand Instant Bank Transfer
      ]
    }
  },
  methods: {
    paymentLogo(value, actualCurrencyCode = '') {
      this.logo = this.setLogo(value, actualCurrencyCode);
      if(this.logo) return this.logo;

      switch (value) {
        // Advcash
        case 'T00100_045':
          this.logo = require('@/assets/images/channel/cps-adv.png')
          break

        // Fasapay
        case 'T00100_051':
          this.logo = require('@/assets/images/channel/fasapay.png')
          break

        // Transact365 (Thailand & Indonesia)
        case 'T00312_021':
          this.logo = require('@/assets/images/channel/local_transfer.png')
          break

        case 'T00600_051':
          this.logo = require('@/assets/images/channel/local_transfer.png')
          break

        // Zotapay (Philippines)
        case 'T00600_053':
          this.logo = require('@/assets/images/channel/local_transfer.png')
          break

        // Eth (Crypto)
        case 'T00400':
          this.logo = require('@/assets/images/channel/eth.png')
          break

        case 'GT00400':
          this.logo = require('@/assets/images/channel/crypto2.png')
          break

        // Paystack (South Africa)
        case 'T00600_052':
          this.logo = require('@/assets/images/channel/local_transfer.png')
          break

        // Wowspay (Vietnam)
        case 'T00312_020':
          this.logo = require('@/assets/images/channel/local_transfer.png')
          break

        // Wowspay-VND-Remit (Vietnam)
        case 'T00600_044':
          this.logo = require('@/assets/images/channel/local_transfer.png')
          break

        // PIX
        case 'T00312_033':
          this.logo = require('@/assets/images/channel/pix.png')
          break

        // SPEI (Mexico)
        case 'T00600_124':
          this.logo = require('@/assets/images/channel/spei.png')
          break

        case 'T00100_069':
          this.logo = require('@/assets/images/channel/fx_ir.png')
          break

        // case 'T00312':
        //   this.logo = require('@/assets/images/channel/alipay.png')
        //   break

        default:
          this.logo = require('@/assets/images/channel/local_transfer.png')
          break
      }

      return this.logo
    },
    langTranslate(prefix, key) {
      const filteredKey = key ? key.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase() : ''
      const comparedKey = prefix + filteredKey
      const translatedLang = i18n.t(prefix + filteredKey)

      return comparedKey === translatedLang ? key : translatedLang
      // return translatedLang // Return Translate Key
    },
    convertDataTestId(value) {
      const filteredKey = value ? value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase() : ''
      return filteredKey
    },
    setLogo(paymentMethod, actualCurrencyCode) {
      if (!paymentSetting[paymentMethod]) return;
      const payment = paymentSetting[paymentMethod].logo;
      if (payment) {
        const logo = payment.additional[actualCurrencyCode]?
          payment.additional[actualCurrencyCode]:
          payment.default;
        return require(`@/assets/images/channel/${logo}.png`)
      } 
      return;
    },
  },
}
